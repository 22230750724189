<template>
  <div :class="['lsf-datepicker', { 'lsf-datepicker__error': hasError }]">
    <div class="form-floating">
      <div class="form-floating__div">
        <input
          type="date"
          :id="id"
          :name="id"
          :value="value"
          :min="min"
          :max="max"
          class="form-control"
          @input="onInput"
        />
        <label :for="id">{{ label }}</label>
      </div>
    </div>
    <div
      :class="['cd-datepicker__footer', { 'has-support-text': supportText }]"
    >
      <p v-if="supportText" class="cd-datepicker__footer__support_text">
        {{ supportText }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: "lsf-datepicker",
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: [Date, String],
      default: ""
    },
    min: {
      type: [Date, String],
      default: () => {
        return moment("1920-01-01").format("YYYY-MM-DD");
      }
    },
    max: {
      type: [Date, String],
      default: () => {
        return null;
      }
    },
    hasError: {
      type: Boolean,
      default: false
    },
    supportText: {
      type: String,
      default: ""
    }
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.value);
    }
  }
};
</script>

<style lang="scss">
.lsf-datepicker {
  .form-floating {
    .form-floating__div {
      .form-control {
        cursor: pointer;
        &:hover {
          background: $brand-primary-100;
        }
      }
      input[type="date"]::-webkit-calendar-picker-indicator {
        background: url("https://files.lxp.academy.who.int/didactica/assets/images/datepicker/calendar.svg");
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  .cd-datepicker__footer {
    @include label-medium($font-weight: 400);
    display: flex;
    justify-content: flex-end;
    &.has-support-text {
      justify-content: space-between;
    }
    .cd-datepicker__footer__support_text {
      padding: 4px 0px 0px 5px;
      color: $brand-neutral-300;
      margin-bottom: 0;
    }
  }
  &.lsf-datepicker__error {
    .form-floating {
      border-color: $brand-error-400;
      &.form-floating--focus {
        border: 1px solid $brand-primary;
      }
    }
    .cd-datepicker__footer__support_text {
      color: $brand-error-400;
    }
  }
}
</style>
